import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import MainLayout from "../components/MainLayout"
import SEO from "../components/seo"
import PageHeading from "../components/PageHeading"
import { Link } from "gatsby"
import './images.scss'


const ImageComponent = styled.div`
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-gap: 20px;
  justify-items: center;
  @media (max-width: 1100px) {
    grid-template-columns: 50% 50%;
  }
  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  margin: 0.5rem auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ImagePage = ({data}) => {
  let Images = []
   data.allContentfulGalleryImages.edges.forEach(edge => {
     let newDate = Date.parse(edge.node.date)
     newDate = (new Date(newDate).toDateString())
     edge.node.date = newDate
    Images.push(edge)
   })
   console.log(Images)
const goToProductPage = (page) => {
  console.log(page)
}
return (
    <>
      <SEO title="Images" description="Timberline Images" />
      <MainLayout>
        <PageHeading title="Images" subHeading="Timberline" />
          <div className="page gallery">
          {Images.map(image => (
            <div>
              <div className="overlay">
              <span><span className="title">Model: </span> <Link to={`/products/${image.node.model.toLowerCase()}`}> {image.node.model}</Link></span> 
              <span><span className="title">Date:</span> {image.node.date}</span>
                </div>
              <Link to={`/products/${image.node.model.toLowerCase()}`}> <img src={image.node.image[0].file.url} alt="" /></Link>
              </div>
          ))}
          </div>
      </MainLayout>
    </>
  )
}

export const query = graphql`
query MyQuery {
  allContentfulGalleryImages {
    edges {
      node {
        useCase
        date
        model
        series
        image {
          file {
            url
          }
          description
        }
      }
    }
  }
}
`

export default ImagePage
